<template>
    <ViewTitle title="後台設定 | 全公司報表" class="detail-viewtitle" />

    <TagMenu :mode="mode" :options="menuOptions" @change-mode="changeMode" />

    <div class="dashboard-container">
        <keep-alive>
            <ContestDownload
                v-if="mode === 'contest'"
                :contestIdDropdownOptions="contestIdDropdownOptions"
                @watch-download="watchDownload"
            />
            <PerformanceDownload v-else-if="mode === 'performance'" />
            <AwardsDownload v-else-if="mode === 'awards'" />
        </keep-alive>
    </div>
</template>

<script>
import _ from 'lodash'
import ViewTitle from '@/components/ViewTitle.vue'
import TagMenu from '@/components/TagMenu.vue'
import ContestDownload from '@/containers/backstage/ContestDownload.vue'
import PerformanceDownload from '@/containers/backstage/PerformanceDownload.vue'
import AwardsDownload from '@/containers/backstage/AwardsDownload.vue'
import { getReportContestCodesAPI } from '@/assets/javascripts/api.js'
import store from '@/store'
import { backstageReportMode } from '@/assets/javascripts/backstage/setting.js'

export default {
    name: 'Contest',
    components: {
        ViewTitle,
        TagMenu,
        ContestDownload,
        PerformanceDownload,
        AwardsDownload
    },
    methods: {
        getContestInfo: async function () {
            if (!this.$hasPermission('generate')) return
            try {
                //* 呼叫 codes 是考量生產報表者權限 *//
                const res = await getReportContestCodesAPI()
                this.dataList = res.data.contests
            } catch {
                this.dataList = []
                this.$showErrorMessage(
                    '載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            }
        },
        watchDownload: function (state) {
            this.reportState = state
            if (state === 'downloading')
                window.addEventListener(
                    'beforeunload',
                    this.beforeunloadHandler
                )
            if (state === 'success')
                window.removeEventListener(
                    'beforeunload',
                    this.beforeunloadHandler
                )
        },
        beforeunloadHandler(e) {
            e.returnValue = ''
            e.preventDefault()
            return ''
        },
        changeMode(mode) {
            const currentMode = this.$route.params.mode
            const newMode =
                mode || (currentMode === 'contest' ? 'performance' : 'contest')
            if (currentMode !== newMode) {
                this.$setGaEvent(`clickGoToMode${newMode}`, 'click-tag')
                this.$router.push({
                    path: `/backstage/report/${newMode}`
                })
            }
        }
    },
    computed: {
        contestIdDropdownOptions: function () {
            return this.dataList.map((data) => {
                return {
                    label: data.contestName,
                    value: data.contestId
                }
            })
        },
        contestInfo: function () {
            const info = _.cloneDeep(
                this.dataList.find((data) => {
                    return data.id === this.contestIdValue
                })
            )
            if (info && !info.stylers) {
                info.stylers = this.defaultInfo.stylers
            }

            const isAdd = this.mode === 'add'

            return isAdd ? this.defaultInfo : info ? { ...info } : {}
        },
        stylerInfo: function () {
            return this.contestInfo?.stylers
                ? { ...this.contestInfo?.stylers }
                : null
        },
        menuOptions() {
            const options = []
            _.keys(backstageReportMode).forEach((key) => {
                if (!this.$hasPagePermission(key)) return
                options.push(backstageReportMode[key])
            })
            return options
        },
        mode() {
            return this.$route.params.mode
        }
    },
    watch: {
        mode: function (val) {
            if (val === 'add') {
                const payload = _.cloneDeep(this.addPayload)
                this.payload = payload
            } else {
                const payload = _.cloneDeep(this.setPayload)
                this.payload = payload
            }
        },
        addPayload: {
            handler() {
                const payload = _.cloneDeep(this.addPayload)
                this.payload = payload
            },
            deep: true
        },
        setPayload: {
            handler() {
                const payload = _.cloneDeep(this.setPayload)
                this.payload = payload
            },
            deep: true
        }
    },
    data() {
        return {
            dataList: [],
            contestIdValue: '',
            reportState: null
        }
    },
    async mounted() {
        await this.getContestInfo()
    },
    beforeRouteEnter(to) {
        const pagePermission = _.startCase(_.camelCase(to.fullPath)).replace(
            /\s/g,
            ''
        )
        if (store.state.permissions[pagePermission]) return

        let routeParams = ''
        _.keys(backstageReportMode).some((key) => {
            if (store.state.permissions[key]) {
                routeParams = backstageReportMode[key].value
                return true
            }
        })

        if (!routeParams) return { name: 'NotFound' }
        if (to.params.mode !== routeParams) {
            return {
                name: 'BackstageReport',
                path: '/backstage/report',
                params: { mode: routeParams }
            }
        }
    },
    beforeRouteLeave() {
        if (this.reportState === 'downloading') {
            let isConfirm = window.confirm('你確定要離開此頁面嗎？下載尚未結束')
            return isConfirm
        }
    }
}
</script>

<style lang="scss" scoped>
.dashboard-container {
    padding: 30px;
}

.scroll-wrapper {
    :deep(.menu-container) {
        // max-width: none;//加上其他管理功能需取消註解
        // @media screen and (min-width: 577px) {//加上其他管理功能需取消註解
        border-bottom: none;
        margin-bottom: 3px;
        padding-left: 0px;
        min-width: 0px;
        width: fit-content;
        :deep(.dashboard) {
            justify-content: flex-start;
        }
        // }//加上其他管理功能需取消註解
        .menu-item {
            margin-right: 10px;
            padding: 0 10px;
        }
    }
}

.info-controller {
    justify-content: space-between;
    height: 60px;
    .button {
        margin-left: auto;
    }
}

.subtitle {
    height: 30px;
    cursor: pointer;
}
</style>
